<template>
  <div class="main">
    <tabs @parentEvent="btn"></tabs>
    <div class="img" v-html="this.$route.query.Content"></div>

    <!--    底部-->
    <div class="button"><footers id="botton"></footers></div>

    <!--    end 底部-->
  </div>
</template>

<script>
import tabs from "@/components/tabs";
import footers from "@/components/footers";

export default {
  props: [],
  components: {
    tabs,
    footers
  },

  data() {
    return { Content: "" };
  },
  update() {
    console.log("updated ");
  },
  mounted() {
    // this.height =  window.getComputedStyle(this.$refs.element).scrollHeight;
    // let middle = this.$refs["element"];
    // this.height = middle.scrollHeight;
    this.Content = this.$route.query.Content;
    console.log(this.$route.query.Content, "test");
  },
  methods: {
    btn() {
      document.documentElement.scrollTop = this.height; //不需要加单位
      document.getElementById("botton").scrollIntoView();
    }
  }
};
</script>
<style lang="less" scoped>
.main {
  background: #f8fbff;
}
.img {
  margin: 0 auto;
  //width: 1125px;
  width: 750px;
  // transform:scale(1.5);
  min-height: 1390px;
}
.button {
  width: 100%;
  bottom: 0px;
}
</style>
